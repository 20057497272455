import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { supabase } from "../../lib/supabase"

export const V_URL = `https://qcpztexbrhbdhqsqlaxr.supabase.co/storage/v1/object/public/videos/`

const getFileUrl = async (filePath) => {
    const { publicURL, error } = supabase
      .storage
      .from('videos')
      .getPublicUrl(filePath);
  
    if (error) {
      console.error("Fayl URL-manzilini olishda xato:", error);
      return null;
    }
  
    return publicURL;
  };

  export const useVideoList = () => {
    return useQuery({
      queryKey: ['videos'],
      queryFn: async () => {
        // Videos ma'lumotlarini olish
        const { data, error } = await supabase
          .from('videos')
          .select('*');
  
        if (error) {
          throw new Error(error.message);
        }
  
        // URL-larni olish va ma'lumotlarni yangilash
        const videoUrls = await Promise.all(
          data.map(async (video) => ({
            ...video,
            videoUrl: video.video ? await getFileUrl(video.video) : null
          }))
        );
  
        return videoUrls;
      }
    });
  };

export const useInsertVideo = () => {
    const queryClient = useQueryClient()
    return useMutation({
        async mutationFn(data) {
            console.log(data)
            const { error, data: newVideo } = await supabase
                .from('videos')
                .insert({
                    title: data.title,
                    title_en: data.title_en,
                    title_cn: data.title_cn,
                    video: data.video
                })
                .single()

            if (error) {
                console.log(error.message)
                throw new Error(error.message)
            }

            return newVideo
        },

        async onSuccess() {
            await queryClient.invalidateQueries({ queryKey: ['videos'] })
        }
    })
}

export const useUpdateVideo = () => {
    const queryClient = useQueryClient()
    return useMutation({
        async mutationFn(data) {
            console.log(data)
            const { error, data: newVideo } = await supabase
                .from('videos')
                .update({
                    title: data.title,
                    title_en: data.title_en,
                    title_cn: data.title_cn,
                    video: data.video
                })
                .eq('id', data.id)
                .single()

            if (error) {
                console.log(error.message)
                throw new Error(error.message)
            }

            return newVideo
        },

        async onSuccess() {
            await queryClient.invalidateQueries({ queryKey: ['videos'] })
        },
        
        async onError() {
            await queryClient.invalidateQueries({ queryKey: ['videos'] })
        }
    })
}

export const useDeleteVideo = () => {
    const queryClient = useQueryClient()
    return useMutation({
        async mutationFn(id) {
            console.log(id)
            const { error } = await supabase
                .from('videos')
                .delete()
                .eq('id', id)

            if (error) {
                console.log(error.message)
                throw new Error(error.message)
            }
        },

        async onSuccess() {
            await queryClient.invalidateQueries({ queryKey: ['videos'] })
        }
    })
}

export const useVideo = (id) => {
    return useQuery({
        queryKey: ['video', id],
        queryFn: async () => {
            const { data, error } = await supabase
                .from('videos')
                .select('*')
                .eq('id', id)
                .single()

            if (error) {
                throw new Error(error.message)
            }

            return data
        }
    })
}